import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";

import BoardHeader from './components/Header/BoardHeader';
import Footer from './components/Footer/Footer';
import ConnectionBoard from './components/ConnectionBoard/ConnectionBoard';
import HelpToolTip from './components/HelpToolTip/HelpToolTip';
import { useParams } from "react-router-dom";

import appStyles from './App.module.css';

function App() {

  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [dailySolution, setDailySolution] = useState();

  const { connectionId } = useParams();

  useEffect(() => {
    //const dat = new Date();
    //const jsonFileName = `${String(dat.getDate()).padStart(2, '0')}-${String(dat.getMonth() + 1).padStart(2, '0')}-${dat.getFullYear()}`;

    // eslint-disable-next-line no-undef
    fetch(process.env.PUBLIC_URL + `/grid/${connectionId}.json`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setDailySolution(data);
      })
      .catch(err => console.log(err));
  }, []);

  function handleHelpButtonClick(shouldOpen) {
    setIsHelpOpen(shouldOpen);
  }

  // function getDate() {
  //   const dat = new Date();
  //   return `${dat.getDate()}. ${dat.toLocaleString('default', { month: 'long' })} ${dat.getFullYear()}`;
  // }

  return (
    <>
      <Helmet>
          <title>{`Denken InBegriffen - Ausgabe ${ (dailySolution ? dailySolution.id : connectionId) }`}</title>
          <link rel="canonical" href={`https://www.denkeninbegriffen.ch/${connectionId }/`} />
      </Helmet>
      <BoardHeader onHelpButtonClick={() => handleHelpButtonClick(true)} />
      <div id="content">
        <div className={appStyles.intro}>
          <h2>{dailySolution && ("#" + dailySolution.id)} - Finde die vier Vierergruppen!</h2>
        </div>
        { dailySolution && <ConnectionBoard dailySolution={dailySolution}/> }
        { isHelpOpen && <HelpToolTip onHelpToolTipCloseClick={() => handleHelpButtonClick(false) }/> }
      </div>
      <Footer />
    </>
  );
}

export default App;
