import PropTypes from 'prop-types';
import HeaderLogo from './HeaderLogo.jsx';
import HelpButton from '../HelpButton/HelpButton.jsx';
import headerStyles from './BoardHeader.module.css';

export default function BoardHeader({ onHelpButtonClick }){
    return (
        <header className={headerStyles.boardHeader}>
             <HeaderLogo additionalLogoClass={headerStyles.gridItem2Col} />
             <HelpButton onHelpButtonClick={onHelpButtonClick} buttonClass={headerStyles.gridItemLastCol}/>
        </header>);
}

BoardHeader.propTypes = {
    onHelpButtonClick: PropTypes.func.isRequired
};