import { Helmet } from "react-helmet";
import HomeHeader from './components/Header/HomeHeader';
import Footer from './components/Footer/Footer';
import homeStyles from './Home.module.css';

function Home() {

  return (
    <>
      <Helmet>
          <title>Denken InBegriffen Rätsel - Finde die vier Vierergruppen!</title>
          <link rel="canonical" href={ "https://www.denkeninbegriffen.ch/" } />
      </Helmet>
      <HomeHeader />
      <h2 className={homeStyles.welcomeText}>
          Willkommen bei Denken InBegriffen!
      </h2>
      <div id="content" className={homeStyles.homeContent}>
        <h3 className={homeStyles.intro}>Denken InBegriffen ist ein Rätsel, bei dem sich alles um Wörter und deren gemeinsame Nenner dreht. 
          Vermeintlich unzusammenhängende Begriffe müssen mit Logik, Kreativität und ein bisschen Wahnsinn in Gruppen zusammengefasst werden.</h3>
        <section className={homeStyles.section}>
        <div>Sechzehn Begriffe sollen in vier thematische Gruppen eingeteilt werden. Einige Worte können in mehrere Gruppen passen - aber es gibt nur eine Zuordnung, bei der jede Gruppe genau vier Worte enthält. </div>
        </section>
        <br />
        <section className={homeStyles.section}>
        <div>
          Jeder Gruppe ist eine Farbe zugeordnet, die sich beim Lösen offenbart. Die Farbe zeigt den Schwierigkeitsgrad der Gruppe an: 
          <br />Von <span className={homeStyles.yellow}>gelb</span> (einfach) über <span className={homeStyles.green}>grün</span> und <span className={homeStyles.blue}>blau</span> zu <span className={homeStyles.violet}>lila</span> (schwierig).
        </div>
        </section>
        <br />
        <section className={homeStyles.section}>
        Insgesamt gibt es sechs Versuche, um die richtige Lösung zu finden. Nach dem sechsten Versuch werden die Gruppen angezeigt.  
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Home;