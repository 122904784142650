import { useState, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { partition } from '../../lib/utilities';

import BoardCell from '../BoardCell/BoardCell';
import ActionBar from '../ActionBar/ActionBar';
import boardStyles from './ConnectionBoard.module.css';

export default function ConnectionBoard({ dailySolution }) {
  const STORAGE_KEY = 'cn-board';
  const initialBoard = {
    selection: [],
    remainingAttempts: 6,
    errorMsg: null,
    solvedGroupIds: [],
    currentGrid: [],
    guesses: [],
  };

  const [board, setBoard] = useState(() => {
    const localData = localStorage.getItem(STORAGE_KEY);
    return localData !== null
      ? JSON.parse(localData)
      : initialBoard;
  });

  useEffect(()=> {
    if (dailySolution) {
        if (board.quizId !== dailySolution.id) {
          localStorage.removeItem(STORAGE_KEY);
          setBoard({...initialBoard, quizId: dailySolution.id, currentGrid: getShuffledOrder([]) });
        }
    }
  }, [dailySolution]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(board));
    if (board.errorMsg !== null) {
      setTimeout(() => { setBoard({...board, errorMsg: null}); }, 3000);
    }
    if (board.showSolvedText) {
      setTimeout(() => { setBoard({...board, showSolvedText: false }) }, 3000);
    }
  }, [board]);

  function validateGroup() {
    if (board.guesses.some((g) => board.selection.every((item) => g.includes(item)))) {
      setBoard({...board, errorMsg: 'Diese Gruppe hast Du schon probiert...'});
      return;
    }

    const solvedGroup = dailySolution.groups.find((g) => g.members.every((item) => board.selection.includes(item)));
    if (solvedGroup) {
      // solvedGroup.members.forEach((text, index) => {
      //   const item = [...document.querySelectorAll('.item')].find(el => el.textContent === text);
      //   item.classList.add(boardStyles.rower, boardStyles.row_0, boardStyles['col_' + index]);
      // });
      
      // setTimeout(() => {
      //   const nextGroups = board.solvedGroupIds.slice();
      //   nextGroups.push(solvedGroup.id);
      //   setBoard({...board, solvedGroupIds: nextGroups, selection: [], currentGrid: getShuffledOrder(nextGroups) });
      // }, 2000);

      const nextGroups = board.solvedGroupIds.slice();
      nextGroups.push(solvedGroup.id);
      const showSolvedText = nextGroups.length === 4 && board.remainingAttempts > 0;
      setBoard({...board, solvedGroupIds: nextGroups, selection: [], showSolvedText: showSolvedText, currentGrid: getShuffledOrder(nextGroups) });
      return;
    }

    const nextGuesses = board.guesses.slice();
    nextGuesses.push(board.selection);
    const attemptsLeft = board.remainingAttempts - 1;

    if (attemptsLeft <= 0) {
      setBoard({...board, 
        guesses: nextGuesses, 
        selection: [],
        errorMsg: 'Nächstes Mal klappts bestimmt!', 
        solvedGroupIds: dailySolution.groups.map(g => g.id),
        remainingAttempts: attemptsLeft,
        currentGrid: []
      });  
    } else {
      setBoard({...board, 
        guesses: nextGuesses, 
        selection: board.selection,
        errorMsg: 'Leider keine Gruppe. Versuche es erneut.', 
        remainingAttempts: attemptsLeft
      });
    }
  }

  function getShuffledOrder(alreadySolvedGroups) {
    const unsolvedCount = dailySolution.groups.reduce((total, g) => {return !alreadySolvedGroups.includes(g.id) ? total+1 : total}, 0) * 4;
    return [...Array(unsolvedCount).keys()]
                    .map(value => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value);
  }

  function handleSubmit() {
    if (board.selection.length !== 4) {
      setBoard({...board, errorMsg: 'Bitte 4 Worte auswählen.' });
      return;
    }
    validateGroup();
  }

  function handleDeselect() {
    setBoard({...board, selection: [] });
  }

  function handleCellClick(cellText) {
    if (board.remainingAttempts <= 0) {
      return;
    }

    if (board.selection.includes(cellText)) {
      setBoard({...board, selection: board.selection.filter((el) => el !== cellText)});
    } else {
      setBoard({...board, selection: [...board.selection, cellText]});
    }
  }

  function getSolvedRow(group) {
    return <div key={`g-${group.id}`} className={cn(boardStyles.solvedGroup, boardStyles['solvedGroup_' + group.id])} >
            <div className={boardStyles.groupDescription}>{group.description}</div>
            <div className={boardStyles.groupMembers}>{group.members.join(', ')}</div>
          </div>;
  }

  function getCell(cellText, rowIndex, cellIndex) {
    const isSelected = board.selection.includes(cellText);
    return <BoardCell 
        itemText={cellText} 
        isInvalid={isSelected && board.errorMsg}
        isSelected={isSelected} 
        rowIndex={rowIndex} 
        cellIndex={cellIndex} 
        onCellClick={handleCellClick} 
        key={`${rowIndex}-${cellIndex}`}
      />;
  }

  function getRows() {
    if (!dailySolution) {
      return; // this should be handled already in the render method...
    }

    const [solved, unsolved] = partition(dailySolution.groups, g => board.solvedGroupIds.includes(g.id));

    const rows = solved.map((g) => getSolvedRow(g));

    board.currentGrid.forEach((id, index) => {
      const rowIndex = Math.floor(id / 4); 
      const cellIndex = id % 4; 
      const gridRowIndex = Math.floor(index / 4); 
      const gridCellIndex = index % 4; 
      rows.push(getCell(unsolved[rowIndex].members[cellIndex], gridRowIndex, gridCellIndex));
    });

    return rows;
  }

  function getCongratsText() {
    switch (board.remainingAttempts) {
      case 6:
        return "Nicht von dieser Welt!";
      case 5:
        return "Genie!";
      case 4:
        return "Beeindruckend!";
      case 3: 
        return "Super!";
      case 2:
        return "Gratuliere!";
      case 1:
        return "Grade noch!";
      default: 
        return "Da lief was schief...";
    }
  }

  const rows = getRows();
  return (
    <div>
      { board.errorMsg && <div className={boardStyles.error}>{board.errorMsg}</div> }
      { board.showSolvedText && <div className={boardStyles.congrats}>{ getCongratsText() }</div> }
      <div className={cn(boardStyles.board, boardStyles.middleAligned )}>
        { dailySolution && rows }
      </div>
      <ActionBar 
        className={boardStyles.middleAligned}
        onSubmit={handleSubmit} 
        onDeselect={handleDeselect}
        canDeselect={board.selection.length > 0}
        canSubmit={board.remainingAttempts > 0 && board.errorMsg === null && board.selection.length === 4} />
      {board.remainingAttempts > 1 && <div className={boardStyles.attempts}>{board.remainingAttempts} Versuche übrig</div>}
      {board.remainingAttempts === 1 && <div className={boardStyles.attempts}>{board.remainingAttempts} Versuch übrig</div>}
    </div>
  );
}

ConnectionBoard.propTypes = {
  dailySolution: PropTypes.object.isRequired,
};