import PropTypes from 'prop-types';
import logoStyles from './HeaderLogo.module.css';
import cn from 'classnames';

export default function HeaderLogo({ additionalLogoClass }){
    return (
            <div className={cn(logoStyles.logo, additionalLogoClass)}>
                <a href="/"><img src="/logo_inbegriffen.svg" alt="Denken InBegriffen Logo" title="Denken InBegriffen Logo" /></a>
            </div>
    );
}

HeaderLogo.propTypes = {
    additionalLogoClass: PropTypes.string
};