import PropTypes from 'prop-types';
import IconButton from '../IconButton/IconButton';
import cn from 'classnames';

import helpButtonStyles from './HelpButton.module.css';

const HelpButton = ({ onHelpButtonClick, buttonClass }) => {

    return (
    <IconButton
      buttonStyleClass={cn(helpButtonStyles.help, buttonClass)}
      buttonText="Wie funktioniert es?"
      iconName="icon-help"
      onClick={onHelpButtonClick}
    />
    );
}

export default HelpButton;

  HelpButton.propTypes = {
    onHelpButtonClick: PropTypes.func.isRequired,
    buttonClass: PropTypes.string
  };